<template>
    <div class="card card-custom">
        <div class="card-header">
            <div class="card-title">
                        <span class="card-icon">
                            <i class="fas fa-user-edit text-aqua"></i>
                        </span>
                <h3 class="card-label text-uppercase">Profile Edit</h3>
            </div>
        </div>
        <div class="card-body">
            <v-form
                    ref="form"
                    v-model="valid"
            >
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <h4 style="margin:10px 0 30px;">Mobile Number: <b class="text-green">{{mobile_number}}</b></h4>

                        <span class="input-level"><i class="far fa-star fa-1x text-danger"></i> Name:</span>
                        <v-text-field
                                dense
                                outlined
                                v-model="form.name"
                                :rules="[ v => !!v || 'Name is required']"
                                required
                        ></v-text-field>
                        <div class="row">
                            <div class="col-md-6">
                                <span class="input-level">Gender</span>
                                <v-select
                                        v-model="form.gender"
                                        :items="genders"
                                        item-text="name"
                                        item-value="value"
                                        dense
                                        outlined
                                ></v-select>
                            </div>
                            <div class="col-md-6">
                                <span class="input-level">Nationality</span>
                                <v-select
                                        v-model="form.country_code"
                                        :items="countries"
                                        item-text="name"
                                        item-value="code"
                                        dense
                                        outlined
                                ></v-select>
                            </div>
                            <div class="col-md-6">
                                <span class="input-level">Email Address</span>
                                <v-text-field
                                        dense
                                        outlined
                                        v-model="form.email"
                                ></v-text-field>
                            </div>
                            <div class="col-md-6">
                                <span class="input-level">Passport Number</span>
                                <v-text-field
                                        dense
                                        outlined
                                        v-model="form.passport_number"
                                ></v-text-field>
                            </div>
                            <div class="col-md-6">
                                <span class="input-level">Password</span>
                                <v-text-field
                                        dense
                                        outlined
                                        type="password"
                                        v-model="new_password"
                                ></v-text-field>
                            </div>
                            <div class="col-md-6">
                                <span class="input-level">Confirm Password</span>
                                <v-text-field
                                        dense
                                        outlined
                                        type="password"
                                        v-model="confirm_password"
                                ></v-text-field>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary rounded-pill px-15" @click="updateProfile" >
                            Update
                        </button>
                    </div>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
    import profileService from  '@/core/services/models/profile.service'
    export default {
        name: "ProfileEdit",
        data() {
            return {
                form:{
                    name: '',
                    gender: '',
                    country_code: '',
                    email: '',
                    passport_number: '',
                },
                mobile_number:'',
                new_password: '',
                confirm_password: '',
                genders: [{name:'Male',value:'male'}, {name:'Female',value:'female'}],
                countries: ['Bangladeshi', 'Bhutani', 'Indian', 'Nepalese'],
                valid: false,
                profileData : null
            };
        },
        methods: {
            updateProfile(e){
                e.preventDefault();
                let data = this.form;
                if(this.new_password != null && this.new_password != ''){
                    if(this.confirm_password == null || this.new_password != this.confirm_password){
                        //console.log(this.new_password)
                        //console.log(this.confirm_password)
                        return this.$toasted.error("Password and confirm password don't match");
                    }
                    else
                        data['password'] = this.new_password;
                }
                //console.log(data)
                profileService.updateProfile(data,s => {
                    this.updatePassengerModel(s)
                    this.$toasted.success('Profile updated successfully')   , e => this.$toasted.error(e)
                })
            },
            updatePassengerModel(p){
                this.mobile_number          = p.mobile_number;
                this.form.name              = p.name;
                this.form.gender            = p.gender;
                this.form.country_code      = p.country_code;
                this.form.email             = p.email;
                this.form.passport_number   = p.passport_number;
            }
        },
        mounted() {
            profileService.getPassengerProfile(s => {
               this.updatePassengerModel(s);
            }, error => {
                console.log(error)
            });
            profileService.getCountryList( c => {
                this.countries = c;
            }, e => {
                console.log(e);
            })
        }
        // genders: ['Male', 'Female'],
        // nationalities: ['Bangladeshi', 'Bhutani', 'Indian', 'Nepalese'],
        // valid:false
    }
</script>

<style>
    .input-level
    {
        font-size: 14px;
        color: #777777;
        margin:0px 0px 5px;
    }

</style>
